.c-product-info-bar {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  background-color: $white-primary;
  border-radius: 1.5rem;

  .c-btn {
    padding: 1.3rem 2.5rem;

    span {
      font-weight: 500;
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 1023px) {
    margin: 2rem 2rem;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid $grey-primary;
    padding: 2rem 4rem;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__left {
      display: flex;
      align-items: center;
      max-width: calc((100% / 3) * 2);

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2.5rem;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      //margin-left: 0.5rem; // temporary off for not showing fav icon

      .favourites-icon {
        width: 2.75rem !important;
        height: auto !important;
        margin-right: 1.375rem;
      }

      span {
        font-size: 3rem;
        font-weight: bold;
        font-family: "itc-avant-garde-gothic-pro", sans-serif;
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
      }
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3.5rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
        margin-bottom: 1rem;

        &__price {
          margin-left: 0;
        }
      }

      span {
        margin-bottom: 0;
        font-size: 1.8rem;
      }

      span:first-child {
        margin-right: 1rem;
        font-weight: 600;
      }

      &__color-circle {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }

  &__breadcrumbs {
    padding: 1.8rem 4rem;

    span {
      font-size: 1.6rem;
    }

    span:nth-child(2n) {
      margin: 0 2rem;
      color: $grey-primary;

      @include mq-tablet {
        margin: 0 0.5rem;
      }
    }
  }

  &__buttons-container {
    display: flex;
    margin-right: 1rem;
    max-width: calc(100% / 3);

    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      justify-content: space-between;
    }

    .btn-download-black {
      display: flex;
      align-items: center;
      background-color: $black-primary;
      margin-left: 1rem;

      @media screen and (max-width: 768px) {
        margin-left: 1rem;
        width: 100%;
      }

      span {
        text-align: center;
        margin: 0 0;
        color: $white-primary;
      }

      img {
        margin-left: 1rem;
      }
    }

    .btn-request {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
