.c-collection {
  border-top: 0.1rem solid $grey-primary;

  &.is-active {
    .c-collection__name span {
      color: $red-primary;
    }

    .c-collection__content {
      display: flex;
    }

    .c-collection__button--expand {
      background-color: $grey-primary;

      span {
        background-color: $black-primary;

        &:last-child {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 1.5rem 4rem;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 550px) {
      padding: 1.5rem 3rem;
      flex-direction: column;
      position: relative;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    flex: 25%;

    @media screen and (max-width: 1023px) {
      flex: none;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      height: 3rem;
      width: 3rem;
      border-radius: 0.5rem;
      background-color: $beige-primary;
      color: $beige-secondary;
      margin-right: 1.5rem;
    }

    span {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &__categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: calc(75% / 2);

    @media screen and (max-width: 1023px) {
      flex: none;
      order: 1;
      width: 100%;
      justify-content: flex-start;
      margin: 2rem 0 0.5rem;
    }

    @media screen and (max-width: 550px) {
      order: 2;
    }
  }

  &__category {
    margin-right: 10rem;

    @media screen and (max-width: 1023px) {
      margin-right: 2rem;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: calc(75% / 2);

    @media screen and (max-width: 1023px) {
      flex: none;
    }

    @media screen and (max-width: 550px) {
      order: 1;
      width: 100%;
      margin: 2rem 0 0.5rem;
      justify-content: space-between;
    }

    &__edit {
      display: flex;
      align-items: center;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3rem;
    color: $black-primary;
    border: none;
    background-color: transparent;
    width: fit-content;
    padding: 0;

    span {
      font-size: 1.6rem;
      font-weight: 600;
    }

    img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1rem;
    }

    &--expand {
      width: 3.5rem;
      height: 3.5rem;
      padding: 1rem;
      border-radius: 0.8rem;
      background-color: #e52713;
      position: relative;
      transition: 0.3s ease-in-out;
      margin-right: 0;

      @media screen and (max-width: 550px) {
        position: absolute;
        top: 1.5rem;
        right: 3rem;
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 1rem;
        height: 0.2rem;
        background-color: #ffffff;

        &:last-child {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__content {
    display: none;
    padding: 0 4rem 1.5rem 4rem;
    flex-direction: column;

    @media screen and (max-width: 550px) {
      padding: 0 3rem 1.5rem 3rem;
    }

    &__items {
      border-radius: 0.8rem;
      margin-bottom: 1rem;
      border: 0.1rem solid $grey-primary;

      &.is-active {
        border: none;
        background-color: #f6f6f6;

        .c-collection__content__items__header__icon {
          background-color: $grey-secondary;

          span {
            background-color: $black-primary;

            &:last-child {
              transform: rotate(0deg);
            }
          }
        }

        .c-collection__content__accordion {
          display: flex;
          flex-direction: column;
        }
      }

      &__header {
        padding: 1.2rem 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__title {
          span {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        &__amount {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          line-height: 1;
          width: 3rem;
          background-color: $black-primary;
          color: $white-primary;
          font-size: 1.4rem;
          font-weight: 600;
          border-radius: 25px;
          margin-left: 1rem;
          padding: 0.5rem 1rem;
        }

        &__icon {
          width: 3.5rem;
          height: 3.5rem;
          padding: 1rem;
          font-weight: 600;
          border-radius: 0.8rem;
          background-color: $red-primary;
          position: relative;
          transition: 0.3s ease-in-out;

          @media screen and (max-width: 1023px) {
            width: 3rem;
            height: 3rem;
          }

          @media screen and (max-width: 550px) {
            width: 2.5rem;
            height: 2.5rem;
          }

          span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 1rem;
            height: 0.2rem;
            background-color: $white-primary;

            &:last-child {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    &__page {
      display: none;

      &.is-active {
        display: flex;
        flex-direction: column;
      }
    }

    &__item {
      width: 100%;
      padding: 1.2rem 3rem;
      border-top: 1px solid #fff;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 1023px) {
        justify-content: space-between;
        padding: 1.5rem 4rem;
      }

      @media screen and (max-width: 550px) {
        padding: 1.5rem 3rem;
      }

      &__left {
        display: flex;
        align-items: center;
        flex: 1;
        width: calc(100% / 4);

        @media screen and (max-width: 1023px) {
          flex: 2;
        }

        .cross {
          background-color: #fff;
          border-radius: 50%;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;
          cursor: pointer;
          width: 1.8rem;
          height: 1.8rem;
          transition: 0.3s ease-in-out;

          &:hover {
            transform: scale(1.3);
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        a {
          width: 100%;
          margin-right: 5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          span {
            font-size: 1.5rem;
            margin-bottom: 0;
            text-decoration: underline;
            width: 100%;

            @media screen and (max-width: 650px) {
              font-size: 1.3rem;
            }
          }
        }
      }

      &__center {
        display: flex;
        align-items: center;
        flex: 1;

        span {
          font-size: 1.5rem;
          margin-bottom: 0;

          @media screen and (max-width: 650px) {
            font-size: 1.3rem;
          }
        }

        @media screen and (max-width: 550px) {
          display: none;
        }
      }

      &__right {
        display: flex;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 1023px) {
          flex: none;
        }

        span {
          font-size: 1.5rem;
          margin-bottom: 0;

          @media screen and (max-width: 650px) {
            font-size: 1.3rem;
          }
        }
      }
    }

    &__accordion {
      display: none;
    }

    &__pagination {
      border-top: 1px solid #fff;
      padding: 2rem 3rem;

      &__container {
        display: flex;
        align-items: center;

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 5px;
          background-color: #000;
          margin-right: 1rem;
          cursor: pointer;
          transition: 0.3s ease-in-out;

          &.is-active {
            background-color: $red-primary;

            span {
              color: #fff;
            }
          }

          &:hover {
            transform: scale(1.1);
          }

          &:last-child {
            margin-right: 0;
          }

          span {
            color: #fff;
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
