.c-products-list {
  &__item {
    border-bottom: 1px solid $grey-primary;

    &:last-child {
      border-bottom: none;
    }

    &.is-active {
      .c-products-list__item__header {
        &__title span {
          color: $red-primary;
        }

        &__icon {
          background-color: $grey-secondary;

          span {
            background-color: $black-primary;

            &:last-child {
              transform: rotate(0deg);
            }
          }
        }
      }

      .c-products-list__sub-items {
        display: block;
      }
    }

    &__header {
      padding: 1.5rem 3.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        padding: 1.5rem 4rem;
      }

      @media screen and (max-width: 550px) {
        padding: 1.5rem 3rem;
      }

      &__title {
        span {
          font-size: 1.6rem;
          font-weight: 600;
          color: $black-primary;
        }
      }

      &__icon {
        width: 3.5rem;
        height: 3.5rem;
        padding: 1rem;
        font-weight: 600;
        border-radius: 0.8rem;
        background-color: $red-primary;
        position: relative;
        transition: 0.3s ease-in-out;

        @media screen and (max-width: 1023px) {
          width: 3rem;
          height: 3rem;
        }

        @media screen and (max-width: 550px) {
          width: 2.5rem;
          height: 2.5rem;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 1rem;
          height: 0.2rem;
          background-color: $white-primary;

          &:last-child {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__sub-items {
    display: none;
    padding: 0 0 2rem 0;

    &--standard-active {
      padding: 2rem 0;
    }
  }

  &__sub-item {
    margin: 0 3.5rem 1rem;
    border: 0.1rem solid $grey-primary;
    border-radius: 0.8rem;

    @media screen and (max-width: 1023px) {
      margin: 0;
      border: none;
      border-radius: 0;
    }

    &.is-active {
      border: none;
      background-color: $grey-secondary;

      @media screen and (max-width: 1023px) {
        background-color: $white-primary;
      }

      .c-products-list__sub-item__header {
        &__icon {
          background-color: $grey-secondary;

          span {
            background-color: $black-primary;

            &:last-child {
              transform: rotate(0deg);
            }
          }
        }
      }

      .c-products-list__sub-item__items {
        display: block;
      }

      .c-products-list__sub-item__items--grid {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        padding: 3rem;
      }
    }

    &--active-white {
      &.is-active {
        background-color: $white-primary;
        border: 0.1rem solid $grey-primary;

        @media screen and (max-width: 1023px) {
          border: none;
        }
      }

      .c-products-list__sub-item {
        @media screen and (max-width: 1023px) {
          margin: 0 3.5rem;
        }

        .c-products-list__sub-items {
          @media screen and (max-width: 1023px) {
            margin: 0 3.5rem;
          }
        }
      }
    }

    &.in-active {
      .c-products-list__sub-items {
        display: none;
      }

      .c-products-list__sub-item__header {
        &__icon {
          background-color: $red-primary;

          span {
            background-color: $white-primary;

            &:last-child {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    &__header {
      padding: 1rem 3.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        padding: 1.5rem 0;
        margin: 0 3.5rem;
        border-top: 1px solid $grey-primary;
      }

      &__title {
        display: flex;
        align-items: center;

        img {
          width: 2rem;
          height: auto;
          margin-right: 1rem;
          transform: rotate(45deg);
        }

        span {
          font-size: 1.6rem;
          font-weight: 600;
          color: $black-primary;
        }
      }

      &__icon {
        width: 3.5rem;
        height: 3.5rem;
        padding: 1rem;
        font-weight: 600;
        border-radius: 0.8rem;
        background-color: $red-primary;
        position: relative;
        transition: 0.3s ease-in-out;

        @media screen and (max-width: 1023px) {
          width: 3rem;
          height: 3rem;
        }

        @media screen and (max-width: 550px) {
          width: 2.5rem;
          height: 2.5rem;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 1rem;
          height: 0.2rem;
          background-color: $white-primary;

          &:last-child {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__items {
      display: none;
    }

    &__item {
      width: 100%;
      padding: 1.5rem 3.5rem;
      border-top: 1px solid $white-primary;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @media screen and (max-width: 1023px) {
        border-color: $grey-primary;
      }

      &.is-active {
        background-color: $grey-primary;

        &:before {
          content: "";
          width: 5px;
          height: 100%;
          background-color: $red-primary;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &:last-child {
        border-bottom: 1px solid $white-primary;
      }

      &__left {
        display: flex;
        align-items: center;
        flex: 1;
        width: calc(100% / 4);

        @media screen and (max-width: 1023px) {
          flex: 2;
        }

        .cross {
          width: 2rem;
          height: 2rem;
          background-color: $white-primary;
          border-radius: 50%;
          padding: 0.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;
          cursor: pointer;

          img {
            width: 100%;
            height: auto;
          }
        }

        .checkbox {
          cursor: pointer;
          appearance: none;
          background-color: $white-primary;
          border: 1px solid #c1c1c1;
          border-radius: 3px;
          display: grid;
          height: 2rem;
          margin: 0 2rem 0 0;
          place-content: center;
          width: 2rem;
          min-width: 2rem;
          min-height: 2rem;

          &:checked:before {
            transform: scale(1);
          }

          &:before {
            box-shadow: inset 1em 1em #0b090a;
            clip-path: polygon(
              14% 44%,
              0 65%,
              50% 100%,
              100% 16%,
              80% 0,
              43% 62%
            );
            content: "";
            height: 0.65em;
            transform: scale(0);
            transform-origin: bottom left;
            transition: transform 0.3s ease-in-out;
            width: 0.65em;
          }

          &-disabled {
            cursor: not-allowed;
            background-color: $grey-primary;
            border: 1px solid $grey-primary;
          }
        }

        .favourites {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;

          img {
            width: 100%;
            height: auto;
          }
        }

        a,
        div {
          width: 100%;
          margin-right: 5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          span {
            font-size: 1.5rem;
            margin-bottom: 0;
            text-decoration: underline;
            width: 100%;

            @media screen and (max-width: 650px) {
              font-size: 1.3rem;
            }
          }
        }
      }

      &__center {
        display: flex;
        align-items: center;
        flex: 1;

        span {
          font-size: 1.5rem;
          margin-bottom: 0;

          @media screen and (max-width: 650px) {
            font-size: 1.3rem;
          }
        }

        @media screen and (max-width: 550px) {
          display: none;
        }
      }

      &__right {
        display: flex;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 1023px) {
          flex: none;
        }

        span {
          font-size: 1.5rem;
          margin-bottom: 0;

          @media screen and (max-width: 650px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
