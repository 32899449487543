.p-product-range {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
      margin-bottom: 1rem;
    }
  }

  &__content {
    display: flex;

    &__range {
      width: 100%;

      &__mobile-nav {
        display: none;
        padding: 2rem 4rem;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1023px) {
          display: flex;
        }

        @media screen and (max-width: 550px) {
          padding: 2rem 3rem;
        }

        .c-product-filter-menu-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          cursor: pointer;

          img {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
          }

          span {
            font-size: 1.6rem;
            font-weight: 600;
          }
        }

        .c-products-layout-switch {
          padding: 0;
        }
      }
    }
  }
}
