.c-product-general-info {
  display: flex;
  flex-direction: column;
  background-color: $white-primary;
  margin-bottom: 2rem;
  padding: 4rem;
  flex: 1;
  border-radius: 1.5rem;
  min-width: 40rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    font-family: "itc-avant-garde-gothic-pro", sans-serif;
  }

  ul {
    padding: 0 0 0 2.5rem;

    li {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 1023px) {
    flex: unset;
    width: 100%;
    margin: 0 2rem 2rem 2rem;
  }

  &--older-model-item {
    min-width: 23.4rem;
  }
}
