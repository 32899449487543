.c-account-nav {
  display: flex;
  align-items: center;
  padding: 0 3.5rem;
  height: 7rem;
  background-color: $white-primary;
  border-bottom: 1px solid $grey-primary;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;

  @media screen and (max-width: 1023px) {
    overflow-y: auto;
    height: 7.5rem;
    padding: 0 4rem;
    border: 1px solid #ebebeb;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__item {
    margin-right: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.is-active:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.5rem;
      background-color: #e52713;
      bottom: 0;
      left: 0;
    }

    span {
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
    }
  }
}
