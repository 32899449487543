/*------------------------------------*\
  #TYPOGHRAPY
\*------------------------------------*/

body {
  font-weight: 400;
  line-height: 1.3;
  font-size: 1.6rem;
  font-family: canada-type-gibson, sans-serif;
  color: $clr-secondary;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  margin: 0 0 0.4em 0;
  font-family: canada-type-gibson, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: $clr-secondary;
}

h1,
.h1 {
  font-size: 3.5rem;

  @media screen and (max-width: 1023px) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 2.8rem;
  }
}

h2,
.h2 {
  font-size: 3rem;

  @media screen and (max-width: 1023px) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 2.5rem;
  }
}

h3,
.h3 {
  font-size: 2.8rem;

  @media screen and (max-width: 1023px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 2.3rem;
  }
}

h4,
.h4 {
  font-size: 2.5rem;

  @media screen and (max-width: 1023px) {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 2rem;
  }
}

h5,
.h5 {
  font-size: 2.3rem;

  @media screen and (max-width: 1023px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.8rem;
  }
}

h6,
.h6 {
  font-size: 2rem;

  @media screen and (max-width: 1023px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.6rem;
  }
}

p,
.p,
span {
  margin: 0 0 1em 0;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2;
  color: $black-primary;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
  }
  a {
    text-decoration: underline;
    color: $black-primary;
  }
}

a {
  color: $black-primary;
  text-decoration: none;
}

small {
  font-size: 1.3rem;
}
