.c-introduction {
  display: flex;
  z-index: 100;
  padding: 0 5rem;
  max-width: 140rem;
  margin: 0 auto;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  &__story {
    overflow: auto;
    max-height: 40rem;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    @include mq-tablet {
      max-height: 20rem;
    }

    &__title {
      font-size: 3rem;
      line-height: 3.6rem;
      @include mq-tablet {
        margin-right: 1rem;
      }
    }
    &__subtitle {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    &__text {
      font-size: 1.7rem;
      line-height: 2.5rem;
    }
  }

  &__arrow {
    height: 5rem;
    width: 5rem;
    border: none;
    border-radius: 100px;
    color: #fff;
    background: #151112;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: #ebebeb;
    }

    img {
      width: 1.8rem;
    }

    &--left {
      margin-right: 10px;
      rotate: 90deg;
    }

    &--right {
      rotate: -90deg;
    }
  }

  &__arrows {
    display: flex;
    flex-direction: row;
  }

  &__button {
    &--skip-part {
      font-size: 1.7rem;
      font-weight: 600;
      background: #ebebeb;
      border: none;
      border-radius: 8px;
      padding: 10px 12px;
      display: flex;
      align-items: center;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    @media (max-width: 700px) {
      margin-top: 20px;
    }
  }

  &__left {
    background: #d4cebf;
    border-radius: 15px 15px 0 0;
    padding: 6.5rem;
    @media (min-width: 700px) {
      width: 60%;
      border-radius: 15px 0 0 15px !important;
    }

    @include mq-tablet {
      padding: 3.5rem;
    }

    &__container {
      min-height: 40rem;

      @include mq-tablet {
        min-height: 15rem;
      }

      img {
        border-radius: 15px;
      }
    }
  }

  &__right {
    padding: 6.5rem;
    background: #fff;
    border-radius: 0 0 15px 15px;
    @media (min-width: 700px) {
      width: 40%;
      border-radius: 0 15px 15px 0 !important;
    }
    @include mq-tablet {
      padding: 3.5rem;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    background: #f6f6f6;
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-tablet {
      height: 5.5rem;
      width: 5.5rem;
    }

    img {
      width: 2.3rem;

      @include mq-tablet {
        width: 2rem;
      }
    }
  }

  &__image-container {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 3rem;

      &__number {
        margin: 0 2rem 0 0;
        padding: 1rem;
        background: #c4b3a3;
        border-radius: 10rem;
        height: 4.5rem;
        width: 4.5rem;
        text-align: center;
        font-size: 2.2rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__text {
        font-size: 2.2rem;
        font-weight: 600;
      }
    }
  }
}

.slide-eight {
  .c-introduction__left__container {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      width: 80%;
    }
  }
}
