/*------------------------------------*\
  #GLOBAL STYLES
\*------------------------------------*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-behavior: smooth;
}

button {
  &:hover {
    cursor: pointer;
  }
}

html {
  font-size: 9px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: 1800px) {
    font-size: 7.5px;
  }

  @media screen and (max-width: 1600px) {
    font-size: 7px;
  }

  @media screen and (max-width: 1500px) {
    font-size: 6.5px;
  }

  @media screen and (max-width: 1400px) {
    font-size: 6px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 5.5px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 5px;
  }

  @media screen and (max-width: 1100px) {
    font-size: 4.5px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 10px;
  }

  @media screen and (max-width: 850px) {
    font-size: 8px;
  }
}

body {
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

select::-ms-expand {
  display: none;
}

// img reset
img {
  max-width: 100%;
  height: auto;
}

// basic hr
hr,
.hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}
