/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Colors names
$black-primary: #151112;
$red-primary: #e52713;
$white-primary: #ffffff;
$beige-primary: #eae7df;
$beige-secondary: #d5cebe;
$grey-primary: #ebebeb;
$grey-secondary: #f6f6f6;
$grey-tertiary: #d9d9d9;
$green-primary: #c3decf;
$pink-primary: #f8c9c4;

// Main colors
$clr-primary: $white-primary;
$clr-secondary: $black-primary;
$clr-tertiary: $red-primary;
$clr-quaternary: $beige-primary;

/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

/*------------------------------------*\
  #RESPONSIVE
\*------------------------------------*/

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;
