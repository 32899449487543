.p-account {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
    }
  }

  &__footer {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: $white-primary;
    border-radius: 1.5rem;
    border: 0.2rem solid $red-primary;
    margin: 2rem 0;
    padding: 2rem;

    @media screen and (max-width: 1023px) {
      margin: 2rem 4rem;
    }

    &.is-active {
      display: flex;
    }

    &__text {
      display: flex;
      flex: 1;
      margin: 1.5rem 4rem;

      p {
        font-weight: 600;
        margin-right: 2rem;
        min-width: 30rem;
        white-space: nowrap;

        @media screen and (max-width: 768px) {
          margin-bottom: 1rem;
        }
      }
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }

    &__button {
      display: flex;
      align-items: center;
      border-radius: 1rem;
      height: 1.5rem;
      background-color: $black-primary;
      padding: 2rem;
      cursor: pointer;
      margin-left: 2rem;

      span {
        margin: 0;
        color: $white-primary;
        font-weight: 600;
      }
    }
  }
}
