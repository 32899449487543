.c-send-code-form {
  display: flex;
  width: 100%;
  margin-top: 1rem;

  &__input {
    margin-right: 1rem;

    input {
      @include mq-tablet {
        width: 100%;
      }
      width: 35rem;
      max-width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      border-left: none;
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      padding: 1rem;
      font-size: 1.6rem;
    }

    .PhoneInputCountry {
      border: 1px solid #ccc;
      border-right: none;
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      padding: 1rem;
      margin-right: 0;
      background-color: #ebebeb;
      color: #000;

      .PhoneInputCountrySelectArrow {
        margin-left: 1rem;
      }
    }

    .PhoneInputCountryIconImg {
      width: 100%;
      height: 100%;
    }
  }
}
