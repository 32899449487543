.p-collections {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
    }
  }

  &__content {
    background-color: $white-primary;
    border: 0.1rem solid $white-primary;
    border-radius: 1.5rem;

    @media screen and (max-width: 1023px) {
      border-radius: 0;
    }

    &__header {
      padding: 2.5rem 4rem;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    &__collections {
      display: flex;
      flex-direction: column;
    }
  }

  &__collection {
    border-top: 0.1rem solid $grey-primary;
    padding: 1.5rem 4rem;

    &__items {
      display: none;
    }

    &.is-active {
      .p-collections__collection__items {
        display: flex;
      }
    }

    &__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: $white-primary;
      color: $black-primary;
      border: none;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__items {
    flex-direction: column;
    background-color: $grey-primary;
    border: 0.1rem solid $grey-primary;
    border-radius: 1.5rem;
    margin: 2rem 4rem;

    &__header {
      display: flex;
      align-items: center;
      margin: 1.5rem 2rem;

      span {
        font-size: 1.6rem;
        font-weight: 600;
        margin-left: 1rem;
      }

      &__amount-number {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.6rem;
        line-height: 1.6rem;
        width: 2.5rem;
        background-color: $black-primary;
        color: $white-primary;
        font-size: 1.2rem;
        font-weight: 600;
        border: 0.1rem solid $black-primary;
        border-radius: 1.5rem;
        margin-left: 8px;
        padding: 1rem 1.2rem;
      }
    }

    ul {
      padding: 0;
      list-style-type: none;

      li {
        border-top: 0.1rem solid $white-primary;
        cursor: pointer;
      }
    }
  }
}
