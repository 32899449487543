.skeleton {
  background-color: #cccccc;
  border-radius: 0.5rem;
  animation: pulse 2s infinite ease-in-out;

  &-carousel {
    border-radius: 1.5rem;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    gap: 10rem;
  }

  &-list {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &-name {
    width: 75px;
    height: 8px;

    &-small {
      width: 6.5rem;
      height: 8px;
    }

    &-super-small {
      width: 30px;
      height: 8px;
    }
  }

  &-title {
    width: 200px;
    height: 15px;
    margin: 0;

    &-small {
      width: 100px;
      height: 15px;
      margin-top: 1rem;
    }

    &-large {
      width: 150px;
      height: 20px;
    }
  }

  &-text {
    width: 400px;
    height: 8px;

    &-small {
      width: 300px;
      height: 8px;
      margin-top: 1rem;
    }

    &-medium {
      width: 100%;
      height: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &-large {
      width: 100%;
      height: 9rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 550px) {
      width: 100px;
    }
  }

  &-company {
    width: 70px;
    height: 8px;
  }

  &-button {
    width: 50px;
    height: 15px;

    &-small {
      width: 25px;
      height: 15px;
    }
  }

  &-dropdown {
    width: 100px;
    padding-left: 10px;
    height: 15px;
  }

  &-navigation {
    width: 125px;
    height: 15px;
  }

  &-breadcrumb {
    width: 100px;
    height: 15px;
    margin-right: 1rem;

    &-slash {
      width: 100px;
      height: 15px;
    }
  }

  &-input {
    display: flex;
    align-items: center;

    &-option {
      width: 90%;
      height: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  &-image {
    width: 15rem;
    height: 15rem;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
