.c-select {
  width: 100%;
  height: 3.5rem;
  position: relative;
  z-index: 2;

  .img-arrow {
    transition: 0.3s ease-in-out;
    width: 1rem;
    height: auto;
  }

  &.is-active {
    .c-select__btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .c-select__container {
      display: block;
    }

    .img-arrow {
      transform: rotate(180deg);
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    padding: 0 1.5rem;
    background-color: $white-primary;
    color: $black-primary;
    border-radius: 8px;
    border: 1px solid $grey-primary;

    &:hover {
      .img-arrow {
        transform: rotate(180deg);
      }
    }

    &__selected {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      span {
        font-size: 1.5rem;
        color: $black-primary;
        margin: 0;

        @media screen and (max-width: 1023px) {
          margin: 0 1.5rem 0 0;
        }
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 3.5rem;
          height: 2rem;
          font-size: 1.1rem;
          line-height: 1;
          font-weight: 700;
          border-radius: 100px;
          color: $black-primary;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;

          &.green {
            background-color: $green-primary;
          }

          &.pink {
            background-color: $pink-primary;
          }
        }
      }

      &--placeholder {
        span {
          color: $grey-tertiary;
        }
      }
    }
  }

  &__container {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $white-primary;
    height: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid $grey-primary;
    border-top: none;

    ul {
      padding: 0;
      list-style-type: none;
    }

    &__option {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 3.5rem;
      border-bottom: 1px solid $grey-primary;
      padding: 0 1.5rem;

      &:last-child {
        border-bottom: none;
      }

      &--placeholder {
        span {
          color: $grey-tertiary;
        }
      }

      span {
        margin: 0;
      }

      .tag {
        width: 3.5rem;
        height: 2rem;
        font-size: 1.1rem;
        line-height: 1;
        border-radius: 100px;
        color: $black-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;

        &.green {
          background-color: $green-primary;
        }

        &.pink {
          background-color: $pink-primary;
        }
      }
    }
  }
}
