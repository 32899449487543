.c-language-picker {
  width: 100%;
  position: relative;

  &--country {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  .img-arrow {
    transition: 0.3s ease-in-out;
    width: 1rem;
    height: auto;
  }

  &.is-active {
    .c-language-picker__btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .c-language-picker__container {
      display: block;
    }

    .img-arrow {
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    flex: 1;

    &--country {
      margin-right: 0;
    }
  }

  &__label {
    margin-bottom: 0.5rem;
    span {
      color: #ffffff;
      font-weight: 600;
      margin: 0;
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    background-color: #212121;
    border-radius: 0.8rem;
    color: #fff;
    border: none;

    &:hover {
      .img-arrow {
        transform: rotate(180deg);
      }
    }

    div {
      display: flex;
      align-items: center;

      span {
        color: $white-primary;
        transition: 0.3s ease-in-out;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  &__container {
    display: none;
    position: absolute;
    bottom: 100%;
    width: 100%;
    background-color: #212121;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    ul {
      padding: 0;
      list-style-type: none;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border-bottom: 1px solid #0e1427;

        &:hover {
          span {
            color: $clr-tertiary;
          }
        }

        span {
          transition: 0.3s ease-in-out;
          color: $white-primary;
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
