.c-breadcrumbs {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    margin-bottom: 2rem;
    padding: 0 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 0 3rem;
  }

  span {
    font-weight: 600;
    font-size: 1.6rem;
    margin-right: 1.5rem;
    margin-bottom: 0;
    color: $black-primary;
  }

  &__arrow {
    margin-right: 1.5rem;
    img {
      transform: rotate(-90deg);
      height: 0.8rem;
      width: auto;
    }
  }
}
