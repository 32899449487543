.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: $black-primary;
    opacity: 0.6;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white-primary;
    border-radius: 1rem;
    width: 78rem;
    border: none;
    padding: 6rem 5.5rem;

    @media screen and (max-width: 1023px) {
      width: calc(100% - 8rem);
    }

    @media screen and (max-width: 650px) {
      padding: 4rem;
    }
  }

  &__header {
    h2 {
      width: 100%;
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 2rem;

      @media screen and (max-width: 650px) {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__close {
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      .c-modal__icon {
        transform: scale(1.2);
      }
    }

    .c-modal__icon {
      transition: all 0.3s ease-in-out;
      height: 2rem;
      width: 2rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    p {
      font-size: 2rem;
      font-weight: 400;

      @media screen and (max-width: 650px) {
        font-size: 1.6rem;
      }
    }

    &__radio-input {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &__group {
        display: flex;
        align-items: center;
        margin-right: 3rem;

        label {
          font-size: 1.8rem;

          @media screen and (max-width: 650px) {
            font-size: 1.4rem;
          }
        }

        input[type="radio"] {
          position: absolute;
          left: -9999px;
        }

        label {
          position: relative;
          padding-left: 3rem;
          cursor: pointer;
          line-height: 2rem;
          display: inline-block;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 2rem;
            height: 2rem;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }

          &:after {
            content: "";
            width: 1.2rem;
            height: 1.2rem;
            background: $red-primary;
            position: absolute;
            top: 0.4rem;
            left: 0.4rem;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }

        input[type="radio"]:not(:checked) + label {
          &:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }

        input[type="radio"]:checked + label {
          &:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }

    &__input {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      label {
        font-size: 1.8rem;

        @media screen and (max-width: 650px) {
          font-size: 1.4rem;
        }
      }

      input[type="text"] {
        width: 100%;
        height: 4.5rem;
        border: 1px solid $grey-primary;
        border-radius: 0.5rem;
        padding: 0 1rem;
        font-size: 1.8rem;
        margin-top: 1rem;

        @media screen and (max-width: 650px) {
          font-size: 1.6rem;
          margin-top: 0.5rem;
        }
      }

      &__label {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        label {
          font-size: 1.8rem;
          font-weight: 400;

          @media screen and (max-width: 650px) {
            font-size: 1.4rem;
          }
        }

        span {
          font-size: 1.4rem;
          font-weight: 400;

          @media screen and (max-width: 650px) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      @media screen and (max-width: 650px) {
        flex-direction: column;
      }

      .c-btn {
        border: none;
        margin-right: 1rem;

        @media screen and (max-width: 650px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .c-btn--secondary {
        background-color: $grey-primary;

        span {
          color: $black-primary;
        }
      }
    }
  }
}

.react-select-indicator {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.collection-select {
  margin-top: 1rem;
}

.react-select-container {
  height: 5rem;
  cursor: pointer;

  .css-t3ipsp-control {
    border-color: #c1c1c1;
    box-shadow: none;
    outline: none;
    height: 5rem;
    border-radius: 8px;

    &:hover {
      border-color: #c1c1c1;
    }
  }

  .css-13cymwt-control,
  .css-13cymwt-control {
    border-radius: 8px;
    height: 5rem;

    &:hover {
      border-color: #c1c1c1;
    }
  }

  .css-hlgwow {
    height: 100%;
  }

  .css-19bb58m {
    margin: 0;
    padding: 0;
  }

  .css-1fdsijx-ValueContainer {
    height: 5rem;
    padding: 0 2rem;
  }

  .css-3w2yfm-ValueContainer {
    height: 5rem;
    padding: 0 2rem;
    overflow-y: auto;
  }

  .css-1dyz3mf {
    height: 5rem;
    padding: 0 2rem;
    overflow-y: auto;
  }

  .css-qr46ko {
    padding-top: 0;
    padding-bottom: 0;
  }

  .css-qbdosj-Input {
    height: 5rem;
    margin: 0;
    padding: 0;
  }

  .css-166bipr-Input {
    height: 5rem;
    margin: 0;
    padding: 0;
  }

  .css-1nmdiq5-menu {
    top: 100%;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }

  .css-1n6sfyn-MenuList {
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .c-products-list__sub-item__item:first-child {
      border-top: none;
    }

    .c-products-list__sub-item__item:last-child {
      border-bottom: none;
    }
  }
}

.css-gkzb1g-MultiValueGeneric {
  font-size: 1.6rem !important;
}
