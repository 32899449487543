.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: $black-primary;
    opacity: 0.6;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white-primary;
    border-radius: 1rem;
    min-width: 40rem;
    border: none;
    padding: 1.8em;

    @media screen and (max-width: 1023px) {
      width: calc(100% - 8rem);
    }

    h5 {
      width: 100%;
      font-size: 1.5rem;
      font-weight: 600er;
      margin-bottom: 2rem;

      @media screen and (max-width: 500px) {
        font-size: 2rem;
      }
    }
  }

  &__image {
    display: flex;
    height: 80vh;
  }

  &__video {
    display: flex;
    margin-top: 1rem;
  }

  &__close {
    padding: 1rem 1rem;
    font-size: 1.8rem;
    font-weight: 600;
    align-self: flex-end;
    border: none;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0.8rem;
    position: absolute;
    top: 0;
    right: 0;

    .c-popup__icon {
      height: 1rem;
      width: 1rem;
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .ctn-btn,
    span {
      font-weight: normal;
    }

    .c-btn,
    .c-btn--primary {
      border: none;
      margin-right: 1.2rem;
    }

    .c-btn--secondary {
      background-color: $grey-primary;

      span {
        color: $black-primary;
      }
    }
  }

  &__lightbox {
    //background-color: red;
    height: 100vh;
    width: 100vw;
  }
}
