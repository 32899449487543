.yarl__thumbnails {
  display: flex;
  height: 100%;
}
.yarl__thumbnails_bottom,
.yarl__thumbnails_end .yarl__thumbnails_track,
.yarl__thumbnails_start .yarl__thumbnails_track,
.yarl__thumbnails_top {
  flex-direction: column;
}
.yarl__thumbnails_wrapper {
  flex: 1;
  position: relative;
}
.yarl__thumbnails_container {
  -webkit-touch-callout: none;
  background-color: rgba(0, 0, 0, 0.85);
  flex: 0 0 auto;
  overflow: hidden;
  padding: 16px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.yarl__thumbnails_vignette {
  --yarl__thumbnails_vignette_size: 12%;
  pointer-events: none;
  position: absolute;
}
@media (min-width: 1200px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 8%;
  }
}
@media (min-width: 2000px) {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 5%;
  }
}

.yarl__thumbnails_thumbnail {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--yarl__thumbnails_thumbnail_background, #000);
  border: var(
    --yarl__thumbnails_thumbnail_border,
    1px solid var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
  border-radius: var(--yarl__thumbnails_thumbnail_border_radius, 4px);
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 0 auto;
  height: var(--yarl__thumbnails_thumbnail_height, 80px);
  outline: none;
  overflow: hidden;
  padding: var(--yarl__thumbnails_thumbnail_padding, 4px);
  position: relative;
  width: var(--yarl__thumbnails_thumbnail_width, 120px);
  margin: 2rem;
}

.yarl__thumbnails_thumbnail_active {
  border: 3px solid
    var(
      --yarl__thumbnails_thumbnail_active_border_color,
      var(--yarl__color_button_active, $red-primary)
    );
}
.yarl__thumbnails_thumbnail_fadein {
  -webkit-animation: yarl__thumbnails_thumbnail_fadein
    var(--yarl__thumbnails_thumbnail_fadein_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadein_delay, 0s) forwards;
  animation: yarl__thumbnails_thumbnail_fadein
    var(--yarl__thumbnails_thumbnail_fadein_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadein_delay, 0s) forwards;
  opacity: 0;
}
.yarl__thumbnails_thumbnail_fadeout {
  -webkit-animation: yarl__thumbnails_thumbnail_fadeout
    var(--yarl__thumbnails_thumbnail_fadeout_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadeout_delay, 0s) forwards;
  animation: yarl__thumbnails_thumbnail_fadeout
    var(--yarl__thumbnails_thumbnail_fadeout_duration, 0.5s) ease-in-out
    var(--yarl__thumbnails_thumbnail_fadeout_delay, 0s) forwards;
  cursor: unset;
}
.yarl__thumbnails_thumbnail_placeholder {
  cursor: unset;
  visibility: hidden;
}
.yarl__thumbnails_thumbnail_icon {
  color: var(
    --yarl__thumbnails_thumbnail_icon_color,
    var(--yarl__color_button, hsla(0, 0%, 100%, 0.8))
  );
  -webkit-filter: var(
    --yarl__thumbnails_thumbnail_icon_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
  filter: var(
    --yarl__thumbnails_thumbnail_icon_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
  height: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: var(--yarl__thumbnails_thumbnail_icon_size, 32px);
}
.yarl__thumbnails_contain_image {
  -o-object-fit: contain;
  object-fit: contain;
}
@-webkit-keyframes yarl__thumbnails_thumbnail_fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes yarl__thumbnails_thumbnail_fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes yarl__thumbnails_thumbnail_fadeout {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes yarl__thumbnails_thumbnail_fadeout {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
