.c-related-products {
  width: calc(50% - 1.25rem);
  margin: 2.5rem 0 3.5rem;
  position: relative;

  .react-multi-carousel-list {
    overflow: visible;
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 0 2.5rem;
  }

  &__slider {
    margin-right: -2rem;
  }

  .carousel-item-padding {
    padding-right: 2rem;
  }

  .rec-item-wrapper {
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      font-size: 3rem;
      font-family: itc-avant-garde-gothic-pro, sans-serif;
      font-weight: bold;
      margin-right: 2.5rem;
      margin-bottom: 0;
    }

    &__nav {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;

      @media screen and (max-width: 1023px) {
        right: 2.2rem;
      }

      &__button {
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);
        background: #fff;
        margin-right: 0.5rem;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          transform: rotate(90deg);
        }

        img {
          height: 0.6rem;
        }
      }
    }
  }

  .gbbKCs {
    margin: 0;
  }

  &__item {
    background: #fff;
    border-radius: 1.5rem;
    width: 100%;
    height: 100%;
    min-height: 31rem;
    transition: 0.3s ease-in-out;

    &:hover{
      -webkit-box-shadow: 0 0 4rem 0.1rem rgba(0,0,0,0.1);
      -moz-box-shadow: 0 0 4rem 0.1rem rgba(0,0,0,0.1);
      box-shadow: 0 0 4rem 0.1rem rgba(0,0,0,0.1);
    }

    &__top {
      border-bottom: 0.1rem solid $grey-primary;
      padding: 2rem;
      position: relative;
      display: flex;
      justify-content: center;

      .carousel-product-image img {
        height: 12.5rem;
      }

      .favorites-icon {
        position: absolute;
        top: 1.4rem;
        right: 1.4rem;
        width: 2rem;

        img {
        }
      }
    }

    &__bottom {
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: calc(100% - 16.5rem);

      .footer-top {
        margin-bottom: 0.8rem;

        span {
          font-family: canada-type-gibson, sans-serif;
          font-weight: 600;
          font-size: 1.5rem;
        }
      }

      .button-container {
        display: flex;
        align-items: center;

        .c-btn {
          a {
            color: #fff;
            font-family: canada-type-gibson, sans-serif;
            font-weight: 500;
            font-size: 1.3rem;
          }

          padding: 0.9rem 1.2rem;
        }

        span {
          flex: 1;
          text-align: center;
          font-size: 1.3rem;
        }
      }
    }
  }
}
