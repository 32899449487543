.c-products {
  background-color: $white-primary;
  border-radius: 0.8rem;

  @media screen and (max-width: 1023px) {
    border-radius: 0;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;
    height: 7rem;

    @media screen and (max-width: 1023px) {
      height: 7.5rem;
      padding: 0 4rem;
      border: 1px solid $grey-primary;

      .c-products-layout-switch {
        display: none;
      }
    }

    @media screen and (max-width: 550px) {
      padding: 0 3rem;
    }
  }
}
