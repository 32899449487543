.c-searchbar {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 0 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 0 3rem;
  }

  &__form {
    width: 45rem;
    height: 5rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0.8rem;
      padding: 2rem 5rem 2rem 2rem;
      font-size: 1.6rem;

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    div {
      position: absolute;
      top: 0;
      right: 0;
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
