.p-product-details {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
      margin-bottom: 1rem;
    }
  }

  // Carousel overwrite
  .rec-slider-container {
    margin: 0 !important;
  }

  // Specification information
  &__specification-info {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  // Product Sliders
  &__product-sliders {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 0;
    justify-content: space-between;

    @media screen and (max-width: 1023px) {
      margin: 1rem 0;
    }

    &__left-container {
      display: flex;
      flex-direction: column;
      flex: 0.5;
      margin: 0;

      @media screen and (max-width: 1023px) {
        //flex: 1;
        margin-left: -1rem;
      }

      @media screen and (max-width: 550px) {
        flex: 1;
        margin-left: -1rem;
        margin-right: 1rem;
      }

      @media screen and (min-width: 1200px) {
        margin-left: 0;
        margin-right: 1rem;
      }
    }

    &__right-container {
      display: flex;
      flex-direction: column;
      flex: 0.5;

      @media screen and (max-width: 1023px) {
        margin-right: -1rem;
      }

      @media screen and (max-width: 550px) {
        flex: 1;
        margin-left: 1rem;
        margin-right: -1rem;
      }

      @media screen and (min-width: 1200px) {
        margin-left: 1rem;
        margin-right: 0;
      }
    }

    &__nav {
      display: flex;
      margin-left: 1.5rem;

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $white-primary;
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 3.5rem;
        padding: 0.9rem;
        margin-top: 0.5rem;

        &__left {
          transform: rotate(90deg);
          margin-right: 1rem;
        }

        &__right {
          transform: rotate(270deg);
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      margin-left: 1rem;

      h1 {
        margin-bottom: 0;
      }

      @media screen and (max-width: 1023px) {
        justify-content: center;
        margin-bottom: 1rem;
      }
    }

    &__slider-left,
    &__slider-right {
      margin-top: 2rem;
      margin-bottom: 1rem;
      min-width: 30rem;
    }

    &__slider-left {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    &__slider-right {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    @media screen and (max-width: 1023px) {
      &__slider-left {
        margin-left: 1rem;
        margin-right: -1rem;
      }
      &__slider-right {
        margin-right: 1rem;
        margin-left: -1rem;
      }
    }
  }

  // Downloads
  &__downloads {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      width: calc(50% - 1.25rem);

      @media screen and (max-width: 1028px) {
        width: 100%;
      }
    }
  }
}
