.c-download-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.1rem 0;
  padding: 1rem 2.3rem;
  background-color: $grey-secondary;
  border-bottom: 1px solid $white-primary;

  > * {
    font-size: 1.5rem;
  }

  a {
    text-decoration: underline;
  }

  span {
    margin-bottom: 0;
  }

  &__left {
    display: flex;
    flex: 1 0 45%;
  }

  &__middle {
    display: flex;
    flex: 1 0 25%;
  }

  @media screen and (max-width: 500px) {
    &__middle {
      display: none;
    }
  }

  &__downloadicon {
    display: flex;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      color: black;
      height: 1.5rem;
      margin-right: 1rem;
    }

    div {
      display: flex;
      align-items: center;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  &__download-icon {
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 1rem;
  }
}
