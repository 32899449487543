.c-product-specs {
  display: flex;
  flex-direction: column;
  background-color: $white-primary;
  padding: 4rem;
  margin-bottom: 2rem;
  flex: 1.75;
  border-radius: 1.5rem;
  margin-left: 2.5rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    font-family: "itc-avant-garde-gothic-pro", sans-serif;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 1023px) {
    flex: unset;
    width: 100%;
    margin: 0 2rem 2rem 2rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: calc((100% / 3) - 2rem);
    margin: 0 2rem 2rem 0;

    @media screen and (max-width: 650px) {
      width: calc(50% - 2rem);
    }

    .item-text-header {
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    .item-energy-label {
      background-color: var(--label-colour);
      color: #fff;
      padding: 0.5rem 0.9rem;
      font-family: canada-type-gibson, sans-serif;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.6rem;
      display: inline-flex;
      position: relative;

      &::before,
      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: -0.9rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 1rem 1.3rem 1rem;
        border-color: transparent transparent var(--label-colour) transparent;
        transform: rotate(0deg);
      }

      &:after {
        transform: rotate(180deg);
        top: auto;
        bottom: 0;
      }
    }

    .item-text {
      //font-size: 1.5rem;
    }

    .item-text-beverages ul {
      padding-top: 0.2rem;
      padding-left: 2rem;
    }

    span {
      margin: 0;
    }
  }

  .spec-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__left {
    margin-right: 1rem;
  }

  &__middle {
    margin-right: 1rem;
  }

  &__right {
    //flex: 1;
  }
}
