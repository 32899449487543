.c-export-bar {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      align-items: center;
      flex: 1;
      margin-bottom: 0;
      margin-right: -3rem;

      @media screen and (max-width: 1023px) {
        flex: none;
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }

    .c-btn {
      height: 3.5rem;

      img {
        margin-left: 1rem;
      }
    }

    &__left {
      display: flex;
      align-items: center;
      flex: 1;

      @media screen and (max-width: 1023px) {
        flex: none;
        width: 100%;
        margin-bottom: 3rem;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      flex: 1;

      @media screen and (max-width: 1023px) {
        flex: none;
        width: 100%;
      }
    }
  }

  &__select-options {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }

  &__download-button {
    span {
      margin: 0;
    }
  }

  &--product-range {
    padding: 2rem;
    background-color: $white-primary;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .c-export-bar__container {
      max-width: 80rem;

      &__title {
        margin-right: 2rem;
        flex: none;
      }
    }
  }
}
