.c-products-selected {
  background-color: $grey-primary;

  @media screen and (max-width: 1023px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }

  &.is-active {
    .c-products-selected__container {
      display: block;
    }

    .c-products-selected__header__right img {
      transform: rotate(0deg);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
    background-color: #151112;
    opacity: 0;
    pointer-events: none;

    @media screen and (max-width: 1023px) {
      &.is-active {
        opacity: 0.75;
      }
    }
  }

  &__header {
    padding: 2rem 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1023px) {
      padding: 2rem 4rem;
    }

    @media screen and (max-width: 550px) {
      padding: 2rem 3rem;
    }

    &__bold {
      font-size: 1.8rem;
      font-weight: 600;
      margin-right: 1rem;
    }

    span {
      font-size: 1.6rem;
    }

    &__right {
      width: 1.5rem;
      height: 1.5rem;

      img {
        width: 100%;
        height: auto;
        transition: 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }

  &__container {
    display: none;
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    width: 100%;
    padding: 1.5rem 3.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $white-primary;

    @media screen and (max-width: 1023px) {
      padding: 1.5rem 4rem;
    }

    @media screen and (max-width: 550px) {
      padding: 1.5rem 3rem;
    }

    &:last-child {
      border-bottom: 1px solid $white-primary;
    }

    &__left {
      display: flex;
      align-items: center;
      flex: 1;
      width: calc(100% / 4);

      @media screen and (max-width: 1023px) {
        flex: 2;
      }

      .cross {
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        background-color: $white-primary;
        border-radius: 50%;
        padding: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .favourites {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      a {
        width: 100%;
        margin-right: 5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        span {
          font-size: 1.5rem;
          margin-bottom: 0;
          text-decoration: underline;
          width: 100%;

          @media screen and (max-width: 650px) {
            font-size: 1.3rem;
          }
        }
      }
    }

    &__center {
      display: flex;
      align-items: center;
      flex: 1;
      width: calc(100% / 4);

      span {
        font-size: 1.5rem;
        margin-bottom: 0;

        @media screen and (max-width: 650px) {
          font-size: 1.3rem;
        }
      }

      &--left {
        @media screen and (max-width: 550px) {
          display: none;
        }
      }

      &--right {
        @media screen and (max-width: 550px) {
          display: none;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      flex: 1;
      width: calc(100% / 4);

      @media screen and (max-width: 1023px) {
        flex: none;
      }

      span {
        font-size: 1.5rem;
        margin-bottom: 0;

        @media screen and (max-width: 650px) {
          font-size: 1.3rem;
        }
      }
    }
  }

  &__footer {
    padding: 2rem 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1023px) {
      padding: 2rem 4rem;
      flex-direction: column;

      &__left {
        margin-bottom: 2rem;
        width: 100%;
      }
    }

    @media screen and (max-width: 550px) {
      padding: 2rem 3rem;
    }

    &__right {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1023px) {
        width: 100%;
        flex-wrap: wrap;
      }

      span {
        margin: 0 1.5rem 0 0;

        @media screen and (max-width: 1023px) {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      .c-btn {
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin-left: 1rem;

        img {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }
    }
  }

  &__selects {
    display: flex;
    align-items: center;
    min-width: 25rem;

    @media screen and (max-width: 1023px) {
      width: calc(100% - 4.5rem);
    }
  }
}
