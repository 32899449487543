.c-account-content {
  padding: 4.5rem 5.5rem 5.5rem;
  display: flex;
  flex-direction: column;
  background-color: $white-primary;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  @media screen and (max-width: 1023px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__wrapper {
    width: calc(100% / 3 * 2);
    height: 20rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: 1023px) {
      width: 100%;
      height: auto !important;
    }
  }

  &__column {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 50%;
    height: 20%;

    @media screen and (max-width: 1023px) {
      width: 100% !important;
      height: auto !important;
      margin-bottom: 1rem !important;
      align-items: flex-start !important;
    }

    &--logo {
      position: absolute;
      width: 50% !important;
      height: calc(100% - 10rem) !important;
      right: 5.5rem;
      top: 4.5rem;

      @media screen and (max-width: 1023px) {
        width: 100% !important;
        height: auto !important;
        position: relative;
        top: initial;
        right: initial;
        justify-content: flex-start;
      }

      .c-account-content__column__text {
        width: auto;

        @media screen and (max-width: 1023px) {
          width: calc(50% - 3rem);
        }
      }
    }

    &__text {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      width: calc(50% - 3rem);
      margin: 0 3rem 0 0;

      &--label {
        font-weight: 600;
      }
    }

    &__inline {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      width: calc(50% - 3rem);
      margin: 0 3rem 0 0;

      &--label {
        font-weight: 600;
        margin-right: .5rem;
      }

      span {
        margin-bottom: 0;
      }
    }

    &__logo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dotted $grey-tertiary;
      border-radius: 1.5rem;
      padding: 3rem;

      @media screen and (max-width: 1023px) {
        width: 20rem;
        height: auto;
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    &__sub-block {
      background-color: $grey-primary;
      border-radius: 1.5rem;
      padding: 4rem 5.5rem;

      @media screen and (max-width: 1023px) {
        width: 100%;
        padding: 2rem;
        height: auto !important;
      }

      .c-account-content__column {
        height: 4rem;
        width: calc(100% / 3);

        @media screen and (max-width: 768px) {
          flex-direction: column;

          .c-account-content__column__text {
            margin-right: 0;

            &--label {
              margin-bottom: 0.5rem;
            }
          }
        }

        &__text {
          width: auto;
        }
      }
    }
  }

  &--company-info {
    margin-bottom: 2rem;
    position: relative;

    .c-account-content__wrapper {
      width: 100%;
      height: 10rem;

      .c-account-content__column {
        width: calc(100% / 3);
        height: 40%;
      }
    }
  }

  &--company-location {
    border-radius: 1.5rem;

    .c-account-content__wrapper {
      height: 8rem;
      margin-bottom: 3rem;
      justify-content: flex-end;

      .c-account-content__column {
        height: 50%;
        margin-right: 3rem;

        &__text {
          width: 40%;

          &--label {
            width: calc(60% - 3rem);
          }
        }
      }
    }
  }

  &__settings-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__settings-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    width: 50%;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }

    &--row {
      flex-direction: row;
    }

    &__text {
      margin-right: 5rem;
    }

    svg {
      width: 15rem;
      height: 15rem;
    }

    label {
      font-size: 1.6rem;
    }

    &__form {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
      flex-wrap: wrap;

      .ReactInputVerificationCode__container {
        height: 4.5rem;
        font-size: 1.8rem;
        margin-right: 2rem;

        .ReactInputVerificationCode__item {
          width: 4.5rem;
          height: 4.5rem;
          font-weight: 400;
        }
      }

      button {
        width: 25%;
        height: 4.5rem;
      }
    }

    .c-account-content__column {
      width: 100%;
      height: auto;
    }
  }

  &__button-container {
    display: flex;
    width: 100%;
    margin: 1rem 0;

    .c-btn {
      margin-right: 1rem;
    }
  }

  &--settings {
    margin-bottom: 2rem;

    .c-account-content__wrapper {
      width: 100%;
      height: 10rem;

      .c-account-content__column {
        width: calc(100% / 3);
        height: 40%;
      }
    }
  }

  &--settings-2fa {
    border-radius: 1.5rem;

    .c-account-content__header {
      margin-bottom: 3rem;
    }
  }
}
