.l-dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: absolute;
    right: 0;

    @media (min-width: 1101px) {
      width: calc(100% - 32.5rem);
    }
  }
}
