.p-spare-parts {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;
  }
}
