.p-search {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;

    .c-searchbar {
      width: 100%;

      &__form {
        width: 100%;
      }
    }
  }

  &__content {
    background-color: $white-primary;
    border: 0.1rem solid $white-primary;
    border-radius: 1.5rem;

    @media screen and (max-width: 1023px) {
      border-radius: 0;
    }

    &__header {
      padding: 2.5rem 4rem;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    &__results-container {
      display: flex;
      flex-direction: column;
    }
  }
}
