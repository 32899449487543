.c-products-layout-switch {
  padding: 3rem 0;
  display: flex;
  align-items: center;

  &__button {
    padding: 1rem 3rem;
    background-color: $grey-secondary;
    color: $grey-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }

    span {
      font-size: 1.6rem;
      font-weight: 600;
    }

    &.is-active {
      background-color: $red-primary;

      span {
        color: $white-primary;
      }
    }

    &:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
    }

    &:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
  }
}
