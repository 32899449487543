.c-tooltip {
  position: relative;
  width: 5rem;
  height: 5rem;
  border: 2px solid #212121;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    cursor: help;

    .tooltiptext {
      pointer-events: all;
      opacity: 1;
    }
  }

  .tooltiptext {
    width: 16rem;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -8rem;
    opacity: 0;
    pointer-events: none;
    font-weight: 600;
    transition: opacity 0.3s;

    @media screen and (max-width: 1100px) {
      font-size: 1.3rem;
      width: 8rem;
      margin-left: -4rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }

  &--product-range {
    position: relative;
    width: 2rem;
    height: 2rem;
    border: none;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .tooltiptext {
      background-color: $grey-secondary;

      &:after {
        border-color: $grey-secondary transparent transparent transparent;
      }
    }
  }
}
