.c-account-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: $white-primary;
  border-radius: 1.5rem;
  border: 0.2rem solid $red-primary;
  margin: 2rem 0;
  padding: 1.5rem 1.5rem 1.5rem 3.5rem;

  @media screen and (max-width: 1023px) {
    margin: 2rem 4rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 1.8rem;
    display: flex;
    font-weight: 600;
    margin: 0;

    @media screen and (max-width: 1023px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    height: 1.5rem;
    background-color: $black-primary;
    padding: 2rem;
    cursor: pointer;
    margin-left: 2rem;

    @media screen and (max-width: 1023px) {
      margin-left: 0;
    }

    span {
      margin: 0;
      color: $white-primary;
      font-weight: 600;
    }
  }
}
