.l-app {
  display: flex;
  padding-left: 32.5rem;

  @media screen and (max-width: 1100px) {
    padding-top: 10rem;
    padding-left: 0;

    &.menu-open {
      .l-app__menu {
        transform: translateX(0);
      }

      .l-app__menu-overlay {
        opacity: 0.75;
      }

      .l-app__mobile-header__buttons__menu span:nth-child(1) {
        top: 10px;
        transform: rotate(135deg);
      }

      .l-app__mobile-header__buttons__menu span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      .l-app__mobile-header__buttons__menu span:nth-child(3) {
        top: 10px;
        transform: rotate(-135deg);
      }
    }
  }

  &__menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
    background-color: #151112;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
  }

  &__mobile-header {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media screen and (max-width: 1100px) {
      display: block;
    }

    &__buttons {
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;

        span {
          width: 100%;
          height: 2px;
          background-color: #fff;
          margin: 0;
        }
      }

      &__menu {
        width: 2.5rem;
        height: 2rem;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;

        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background-color: #fff;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 2px;
          }

          &:nth-child(2) {
            top: calc(2rem / 2);
          }

          &:nth-child(3) {
            top: calc(2rem - 2px);
          }
        }
      }

      img {
        width: auto;
        height: 2rem;
      }
    }

    &__top {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;
      height: 6rem;
      background-color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4rem;

      @media screen and (max-width: 550px) {
        padding: 0 3rem;
      }

      .l-app__mobile-logo {
        margin: 0;
        width: 10rem;
        height: auto;
      }
    }

    &__bottom {
      position: absolute;
      top: 6rem;
      left: 0;
      width: 100%;
      height: 4rem;
      background-color: $clr-primary;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4rem;

      @media screen and (max-width: 550px) {
        padding: 0 3rem;
      }
    }

    &__name {
      color: #000;
      margin: 0;
      font-weight: 600;
      font-size: 1.2rem;
    }

    &__date {
      color: #000;
      margin: 0;
      font-size: 1.2rem;
    }
  }

  &__menu {
    width: 32.5rem;
    height: 100vh;
    padding: 5rem 4.5rem 4.5rem 4.5rem;
    transition: 0.3s all;
    background-color: $clr-secondary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1100px) {
      top: 6rem;
      z-index: 4;
      width: 100%;
      height: fit-content;
      transform: translateX(-100%);
      background-color: #000;
      padding: 1rem 4.5rem 4.5rem 3rem;
    }

    @media screen and (max-width: 550px) {
      padding: 1rem 3rem 3rem 3rem;
    }

    &__search-bar {
      display: none;
      width: 100%;
      height: 4rem;
      position: relative;
      margin-bottom: 3rem;

      @media screen and (max-width: 1100px) {
        display: block;
      }

      input {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: none;
        padding: 2rem;
        font-size: 1.4rem;
      }

      &__icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 4rem;
        height: 4rem;
        padding: 1.2rem;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &__nav {
      ul {
        padding: 0;

        li {
          margin-bottom: 2rem;
          display: flex;

          a {
            display: flex;
            align-items: center;

            &:hover {
              span {
                text-decoration: underline;
                text-underline-offset: 2px;
              }
            }

            span {
              color: $white-primary;
              font-size: 1.6rem;
              font-weight: bold;
              font-family: "itc-avant-garde-gothic-pro", sans-serif;

              @media screen and (max-width: 1100px) {
                font-size: 1.5rem;
              }
            }

            .icon {
              width: 4rem;
              height: 4rem;
              background-color: #212121;
              border-radius: 8px;
              margin-right: 2rem;
              padding: 1.1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              @media screen and (max-width: 1100px) {
                width: 3rem;
                height: 3rem;
                padding: 0.7rem;
              }

              .logo {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        .isActive {
          a {
            .icon {
              background-color: $clr-tertiary;
            }

            span {
              color: $clr-tertiary;
              text-decoration: underline;
              text-underline-offset: 2px;
            }
          }
        }
      }
    }

    &__account {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media screen and (max-width: 1100px) {
        margin-top: 3rem;
      }

      &__info {
        display: flex;
        flex-direction: column;
      }

      &__name {
        color: $clr-primary;
        font-weight: 600;
        margin: 0;

        @media screen and (max-width: 1100px) {
          font-size: 1.5rem;
        }
      }

      &__company {
        line-height: 1;
        color: $clr-tertiary;

        @media screen and (max-width: 1100px) {
          font-size: 1.3rem;
        }
      }

      .log-out {
        color: $clr-primary;
        text-decoration: underline;
        cursor: pointer;

        @media screen and (max-width: 1100px) {
          font-size: 1.5rem;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__translate {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 2rem;
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 10rem;

    @media screen and (max-width: 1100px) {
      display: none;
    }

    img {
      width: 20rem;
    }
  }

  &__content {
    width: 100%;
    min-height: 100vh;
    background-color: #f3f3f3;
    padding: 3.8rem 8rem 12rem 8rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1023px) {
      padding: 2rem 0 12rem;
    }

    &__container {
      height: 100%;
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }

      div {
        display: flex;
        align-items: center;
        @media screen and (max-width: 500px) {
          margin-top: 1.5rem;
        }

        a,
        button {
          margin-left: 1rem;

          @media screen and (max-width: 500px) {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }

      &--public {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104rem;
        margin: 0 auto;

        @media screen and (max-width: 1040px) {
          width: 100%;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 15rem;
          max-height: 8rem;

          @media screen and (max-width: 600px) {
            max-width: 10rem;
            max-height: 6rem;
          }
        }

        figure {
          width: 2px;
          background-color: #d3e0e5;
          height: 5rem;
          display: block;
          margin: 0 2rem;

          @media screen and (max-width: 600px) {
            height: 3rem;
          }
        }

        h1 {
          font-size: 2.2rem;
          margin: 0;
          font-weight: 600;

          @media screen and (max-width: 600px) {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
