.c-product-downloads {
  flex-direction: column;
  background-color: $white-primary;
  border-radius: 1.5rem;

  @media screen and (max-width: 1023px) {
    margin: 0 2rem 2rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }

  &__top {
    border-bottom: 0.1rem solid $grey-primary;
    width: 100%;
    padding: 4rem 4rem 2rem;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;

      h3 {
        font-size: 3rem;
        margin-right: 1.1rem;
        font-family: "itc-avant-garde-gothic-pro", sans-serif;
        font-weight: bold;
        margin-bottom: 0;
      }

      span {
        background-color: $black-primary;
        color: $white-primary;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 3rem;
        font-family: "itc-avant-garde-gothic-pro", sans-serif;
        font-size: 1.7rem;
      }
    }

    &__nav {
      display: flex;

      &__item {
        margin-right: 3rem;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.8rem;

        //span {
        //  display: block; Never make an inline element a block element, it's bad practice
        //}

        &.is-active {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 0.5rem;
            background-color: $red-primary;
            top: 3.9rem;
            left: 0;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 4rem;

    &__item {
      display: flex;
      justify-content: space-between;
      margin: 0.1rem 0;
      padding: 1rem 2.3rem;
      background-color: $grey-secondary;

      > * {
        font-size: 1.5rem;
      }

      a {
        text-decoration: underline;
      }

      span {
        margin-bottom: 0;
      }

      &__left {
        display: flex;
        flex: 1 0 45%;

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
          cursor: pointer;
          text-decoration: underline;

          @media screen and (max-width: 1400px) {
            max-width: 200px;
          }
        }
      }

      &__middle {
        display: flex;
        flex: 1 0 25%;
      }

      @media screen and (max-width: 500px) {
        &__middle {
          display: none;
        }
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          color: black;
          height: 1.5rem;
          margin-right: 1rem;
        }

        div {
          display: flex;
          align-items: center;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  &__download-icon {
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 1rem;
  }
}
