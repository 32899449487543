.c-products-nav {
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 1023px) {
    overflow-y: auto;
  }

  &__item {
    margin-right: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
    }

    &.is-active {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.5rem;
        background-color: $red-primary;
        bottom: 0;
        left: 0;
      }
    }

    .c-favourites {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
