.c-btn {
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background-color: $grey-primary;
    border: 1px solid $white-primary;

    &:hover {
      transform: scale(1);
    }
  }

  &--primary {
    background-color: $red-primary;

    span {
      color: $white-primary;
    }
  }

  &--secondary {
    background-color: $grey-secondary;

    span {
      color: $grey-primary;
    }
  }

  &--fit-content {
    width: fit-content;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__transparant {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
