.c-result-category {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0;
  width: 100%;
  border-top: 1px solid #ebebeb;

  &__header {
    padding: 0 4rem;
    display: flex;
    align-items: center;

    &__title {
      span {
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    &__amount {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      line-height: 1;
      width: 3rem;
      background-color: #f6f6f6;
      font-size: 1.4rem;
      font-weight: 600;
      border-radius: 25px;
      margin-left: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 3rem;

    .c-product-grid-item {
      border: 1px solid $grey-primary;
      width: calc(20% - 1.2rem);

      @media screen and (max-width: 1023px) {
        width: calc(50% - 1.2rem);
      }

      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
  }
}
