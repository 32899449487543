.c-product-carousel {
  position: relative;
  margin-top: 4rem;

  .carousel-button-group {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .carousel-item-padding {
    padding: 0 1rem;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  .gbbKCs {
    overflow: visible !important;
  }

  @media screen and (max-width: 1023px) {
    margin: 1rem 2rem;
  }

  &__navigation {
    position: relative;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      z-index: 1;
      background-color: $grey-primary;
      height: 5rem;
      width: 5rem;
      border-radius: 4.5rem;

      &:hover {
        cursor: pointer;
        background: #000;
      }

      img {
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%) !important;
      }

      &--left {
        left: 1.5rem;
        margin-left: 2rem;

        img {
          transform: rotate(90deg);
        }
      }

      &--right {
        right: 1.5rem;
        margin-right: 2rem;

        img {
          transform: rotate(270deg);
        }
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-primary;
    border-radius: 1.5rem;
    cursor: pointer;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;

    &__banner {
      display: flex;
      flex: 1;
      height: 45rem;

      img {
        width: auto;
        height: 90%;
      }
    }

    .video-banner-slide {
      width: 100%;
      height: 100%;
    }

    &__product:last-child {
      width: 400px;
      height: 450px;
      margin: 0 1rem;

      img {
        width: 200px;
        height: 85%;
      }
    }

    &__video {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 40rem;
      height: 45rem;

      margin: 0 2rem;
    }

    &__product {
      display: flex;
      flex-direction: column;
      flex: 1 1 30%;
      margin: 0 1rem;

      &__top {
        display: flex;
        flex-direction: column;
        height: 75%;
        width: 100%;
        margin-bottom: 2rem;

        .favorites-icon {
          display: flex;
          justify-content: flex-end;
          margin: 0 2rem 2rem 0;

          img {
            width: 2rem;
            height: 2rem;
          }
        }

        .carousel-product-image {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: auto;
            height: 75%;
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        //width: 30rem;
        width: 100%;
        border-top: 0.1rem solid $grey-primary;
        padding: 1.5rem 0;

        .footer-top span {
          font-weight: bold;
          margin: 2.5rem;
          padding-bottom: 5rem;
        }

        .button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 2.5rem;

          .btn-view-product {
            padding: 0.4rem 0.8rem;
            font-weight: normal;

            a {
              color: $white-primary;
            }
          }
        }
      }
    }

    &__video-player {
      display: flex;
      height: 85%;
    }

    &__control-icon {
      display: flex;
      align-self: flex-start;
      margin-bottom: 2rem;
      height: 5rem;
    }

    &__expand-icon,
    &__play-icon {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey-primary;
      height: 4rem;
      width: 4rem;
      border-radius: 0.8rem;
      padding: 0.5rem;

      img {
        height: 1.7rem;
      }
    }

    &__play-icon {
      height: 2rem;
      width: 2rem;
      padding: 0.5rem;
      background-color: $black-primary;
    }
  }
}
