.c-product-filters {
  width: 35rem;
  height: fit-content;
  background-color: $white-primary;
  border-radius: 0.8rem;
  margin-right: 1rem;
  transition: 0.3s all;

  @media screen and (max-width: 1023px) {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    transform: translateX(-100%);
    margin: 0;
    height: 100dvh;
    border-radius: 0;
    overflow: auto;

    &.is-active {
      transform: translateX(0);
    }
  }

  &__mobile-footer {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: $grey-primary;
    font-weight: 600;
    cursor: pointer;

    img {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
    }

    span {
      font-size: 1.6rem;
      font-weight: 600;
    }

    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }

  &__filter {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    border-bottom: 1px solid $grey-primary;

    &:last-child {
      border-bottom: none;
    }

    &.is-active {
      .c-product-filters__filter__header__icon span:last-child {
        transform: rotate(0deg);
      }

      .c-product-filters__filter__options {
        display: block;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &__title {
        display: flex;
        align-items: center;
        margin: 0 1.5rem 0 0;
        width: 100%;

        span {
          margin: 0;
          font-size: 1.6rem;
          font-weight: 600;
          display: block;
          width: 100%;
        }

        .c-tooltip {
          margin-left: 1rem;
        }
      }

      &__icon {
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        padding: 1rem;
        font-weight: 600;
        border-radius: 0.8rem;
        background-color: $grey-primary;
        position: relative;
        transition: 0.3s ease-in-out;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          min-width: 1rem;
          width: 1rem;
          min-height: 0.2rem;
          height: 0.2rem;
          background-color: $black-primary;

          &:last-child {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__options {
      margin-top: 1.5rem;
      display: none;

      @media screen and (max-width: 1023px) {
        display: block;
      }
    }

    &__option {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .checkbox {
        cursor: pointer;
        appearance: none;
        background-color: $white-primary;
        border: 1px solid #c1c1c1;
        border-radius: 3px;
        display: grid;
        height: 2rem;
        margin: 0;
        place-content: center;
        width: 2rem;
        margin-right: 1rem;

        &:checked:before {
          transform: scale(1);
        }

        &:before {
          box-shadow: inset 1em 1em #0b090a;
          clip-path: polygon(
            14% 44%,
            0 65%,
            50% 100%,
            100% 16%,
            80% 0,
            43% 62%
          );
          content: "";
          height: 0.65em;
          transform: scale(0);
          transform-origin: bottom left;
          transition: transform 0.3s ease-in-out;
          width: 0.65em;
        }
      }

      span {
        font-size: 1.5rem;
      }
    }
  }

  &__btn {
    &-reset {
      width: 100%;
      background-color: #ebebeb;
      border: none;
      border-radius: 0.5rem;
      padding: 0.8rem 0;
      display: flex;
      justify-content: center;
      gap: 1rem;
      pointer-events: none;

      span {
        font-size: 1.5rem;
        font-weight: 500;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      &__container {
        margin: 2.5rem;
      }

      &--active {
        pointer-events: all;
        background-color: #e52713;

        span {
          color: white;
        }
      }
    }
  }
}
